<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedUserActions"
        :default-value="properties.action"
        step-label="user"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>
    <v-col v-if="properties.action === 'CREATE_USER'" cols="12">
      <v-text-field
        :key="`${step.id}-name`"
        v-model="properties.fields.name"
        outlined
        dense
        :label="$lang.labels.name"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <v-col
      v-if="!['GET_USER_DETAILS', 'GET_USER_ROLES', 'GET_USERS_BY_ROLES']
        .includes(properties.action)"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-email`"
        v-model="properties.fields.email"
        outlined
        dense
        :label="$lang.labels.email"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <v-col
      v-if="properties.action === 'CREATE_USER'
        || properties.action === 'GET_USERS_BY_ROLES'"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-roles`"
        v-model="properties.fields.roles"
        outlined
        dense
        :label="$lang.labels.roles"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
      />
    </v-col>
    <v-col v-if="properties.action === 'VALIDATE_API_KEY'" cols="12">
      <v-text-field
        :key="`${step.id}-apiKey`"
        v-model="properties.fields.apiKey"
        outlined
        dense
        :label="$lang.labels.apiKey"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <v-col
      v-if="properties.action === 'GENERATE_API_KEY'
        || properties.action === 'VALIDATE_PASSWORD'"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-password`"
        v-model="properties.fields.password"
        outlined
        dense
        :label="$lang.labels.password"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <v-col
      v-if="properties.action === 'GET_USER_DETAILS'
        || properties.action === 'GET_USER_ROLES'"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-userId`"
        v-model="properties.fields.userId"
        outlined
        dense
        :label="$lang.labels.userId"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.fields.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ActionSelect } from './components'
import StepMixin from './StepMixin'

import { USER_ACTIONS } from './constants'

export default {
  name: 'UserStep',
  components: { ActionSelect },
  mixins: [StepMixin],
  computed: {
    formattedUserActions() {
      return USER_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.fields) this.properties.fields = {}
  }
}
</script>
